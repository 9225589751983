export default {
  'header': {
    'title': 'Commande du %{date}',
    'button': 'Détail',
    'date-hours': 'Entre %{from} et %{to}',
    'disclaimer-being-delivered':
      "Un livreur a pris en charge votre commande, vous ne pouvez plus l'annuler ou la modifier",
    'disclaimer-being-prepared':
      "Un préparateur a pris en charge votre commande, vous ne pouvez plus l'annuler ou la modifier",
    'disclaimer-about-to-be-prepared':
      "Un préparateur va prendre en charge votre commande, vous ne pouvez plus l'annuler ou la modifier",
  },
  'summary': {
    'title': 'Résumé de la commande',
    'address': 'Adresse de livraison',
    'pickup-point': 'Adresse de retrait',
    'pickup-infos': 'Informations de retrait',
    'address-comment': 'Complément d’information',
    'fullname': 'Nom - Prénom',
    'expected-delivery': 'Livraison souhaitée',
    'expected-pickup': 'Retrait sur place',
    'date': 'Le %{date} entre %{from} et %{to}',
    'see-map': 'Voir sur la carte',
  },
  'cancel-confirmation-message': 'Voulez-vous vraiment annuler la commande ?',
  'invoices-title': 'Factures',
  'download-invoice': 'Télécharger',
  'products': {
    'title': 'Panier',
    'product-quantity': '%{count} article',
    'product-quantity_plural': '%{count} articles',
    'header': {
      'articles': 'Articles',
      'prepared-quantity': 'Quantité préparée',
      'requested-quantity': 'Quantité souhaitée',
      'quantity-to-be-paid': 'Quantité facturée',
    },
    'max-product-price': 'Montant max',
    'legacy-error': "Impossible d'afficher la liste des produits.",
    'is-missing': 'Rupture de stock',
    'not-charged': 'Non facturé',
    'offered': 'Offert',
    'offered-price': 'Offert +%{price}',
    'replaced-by': 'Remplacé par',
    'update-disclaimer-1':
      "En fonction des arrivages de nos produits, certains attributs comme la provenance sont susceptibles d'être modifiés",
    'update-disclaimer-2':
      'Pour ces produits, le prix définitivement facturé correspond au poids réel mesuré lors de la préparation de commandes',
    'totals': {
      'total-products-estimated': 'Total article (estimé)',
      'total-products-retained': 'Total article (retenu)',
      'preparation-fee': 'Préparation',
      'delivery-fees': 'Livraison',
      'delivery-offered': 'Offert',
      'total-final-estimated': 'Total final (estimé)',
      'total-final-real': 'Total final réel',
      'total-VAT': 'dont TVA %{rate}% (%{letter})',
      'total-discount': 'Remises et coupons',
      'temporary-payment': 'Paiement provisoire',
      'total-temporary-payment': 'Total paiement provisoire',
      'pre-authorization': 'Pré-autorisation',
      'estimated-reimbursement': 'Remboursement estimé après livraison',
      'expected-reimbursement': 'Remboursement prévu à livraison',
      'final-reimbursement': 'Remboursement effectué',
      'exceptional-reimbursements': 'Remboursement(s) exceptionnel(s)',
    },
    'piece': 'pièce',
    'piece_plural': 'pièces',
    'x-free': '%{count} offert',
    'x-free_plural': '%{count} offerts',
    'prepaid-at': 'Ajouté le %{date}',
  },
  'payments': {
    'title': 'Paiement',
    'paid-at': 'Payé le %{cardType}',
    'apple-pay': 'Apple Pay',
    'google-pay': 'Google Pay',
    'paypal': 'Paypal',
    'visa': 'Visa',
    'mastercard': 'Mastercard',
    'cb': 'Carte Bleue',
  },
  'cancellation-done-message': 'Votre commande a bien été annulée',
  'select-zone': {
    'delivery-marker-text': 'Domicile',
    'pickup-distance-from-address': '%{distance} km de votre adresse',
  },
  'update-order': {
    title: 'Voulez-vous vraiment modifier votre commande ?',
    content:
      'La préparation de votre commande sera annulée et vous ne serez pas débité.<br><br>Les produits de votre commande seront conservés dans votre panier selon disponibilité et vos codes de réductions seront toujours valides.',
    footer:
      "En modifiant votre commande, la disponibilité du créneau de livraison, des produits, des prix et promotions n'est plus garantie. Si vous avez un autre panier en cours de constitution, il sera remplacé par votre panier à modifier.",
    toaster: {
      title: 'Vous pouvez modifier votre commande',
      content: 'Attention, vous devez la valider à nouveau une fois votre modification terminée',
    },
    error: {
      title: 'Délai dépassé',
      content:
        "Un préparateur a pris en charge votre commande, vous ne pouvez plus l'annuler ou la modifier.",
    },
  },
  'update-slot': {
    'title-error': 'Délai dépassé',
    'no-slots': 'Aucun autre créneau n’est disponible pour votre jour de livraison.',
  },
  'weight-explanation-panel': {
    'title': 'Au gramme près :',
    'description': 'comme sur votre marché, vos produits sont facturés au poids réel.',
    'know-more': 'En savoir plus',
  },
};
