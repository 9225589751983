import constants from '~/src/constants';

export default {
  'signin-action': "J'ai déjà un compte",
  'signin-subtitle': 'Déjà inscrit ?',
  'signup-action': 'Créer mon compte',
  'signup-subtitle': 'Pas encore inscrit ?',
  'forgotten-password-form': {
    title: 'Réinitialisation de votre mot de passe',
    description: 'Un email va vous être envoyé afin de réinitialiser votre mot de passe',
  },
  'signin-form': {
    title: 'Connexion à mon compte',
    description: 'Veuillez renseigner vos informations',
  },
  'signup-form': {
    'title': 'Création de mon compte',
    'signup': 'Je m’inscris',
    'acquisition-source': {
      label: 'Comment nous avez-vous connus ?',
      placeholder: 'Bouche à oreille',
    },
    'email': {
      label: 'Identifiant',
      placeholder: 'Votre adresse email',
    },
    'phone': {
      'label': 'Téléphone',
      'label-info': 'Utilisé pour votre suivi de commande et communiquer avec nos livreurs',
      'placeholder': '0612345678',
    },
    'password': {
      label: 'Mot de passe',
      placeholder: 'Mot de passe',
    },
    'referral': {
      'label': "J'ai un code parrain",
      'aria-label-enable': "J'ai un code parrain",
      'aria-label-disable': "Je n'ai pas de code parrain",
      'placeholder': '(ex: EF518M)',
    },
    'referral-gift': {
      'offered-cart': 'Un panier offert',
      'description': 'par votre parrain pour votre première commande !',
      'invitation':
        'Un produit offert grâce à votre parrain et son code « <b>%{referralCode}</b> »',
      'invitation-anonymous':
        'Bravo ! Il vous suffit de finaliser votre inscription pour bénéficier d’un cadeau.',
    },
    'optin': {
      'aria-label': "M'inscrire aux communications commerciales de mon-marché.fr",
      'label': "J'accepte de recevoir le suivi de livraison et des bons plans par email ou SMS.",
    },
    'read-accept-CGU-1': "J'ai lu et j'accepte les ",
    'read-accept-CGU-2': 'CGV',
    'read-accept-CGU-3': ` de ${constants.BRAND_NAME}*`,
    'cgu': "J'ai lu et j'accepte les CGV de mon-marché.fr",
    'read-accept-confidentiality-1': "J'ai lu et j'accepte la ",
    'read-accept-confidentiality-2': 'politique de confidentialité*',
    'confidentiality': "J'ai lu et j'accepte la politique de confidentialité",
    'submit': 'Je crée mon compte',
  },
  'otp-signup-form': {
    'title': 'Confirmation du numéro',
    'description':
      'Par mesure de sécurité et pour assurer un bon déroulé de commande, saisissez le code que vous avez reçu par SMS au ',
    'resend-label': "Vous n'avez pas reçu de code ?",
    'resend-button_0': 'Renvoyer un code',
    'resend-button_1': 'Renvoyer un code dans %{count} seconde',
    'resend-button_plural': 'Renvoyer un code dans %{count} secondes',
  },
  'banner-message': {
    'add-bookmark': 'Vous devez vous créer un compte ou vous connecter pour ajouter un favori !',
    'display-bookmarks-list':
      'Vous devez vous créer un compte ou vous connecter pour consulter vos favoris !',
  },
};
