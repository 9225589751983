import I18n from '~/src/common/services/I18n';
import { formatBatchDiscountNthText } from '~/src/common/utils/prices';
import { Home200ItemsItemProductsItemOneOfPromoConditionsType } from '~/src/queries/api-ecom/generated/api-ecom.schemas';

import * as S from './layout';

export interface Props {
  nthQuantity: number;
  type: Home200ItemsItemProductsItemOneOfPromoConditionsType;
  value: number;
  cartQuantity: number;
  isVisible: boolean;
  discountPerBulk: number | undefined;
  itemOriginalPrice: number;
}

const NthPromoTooltip = ({
  isVisible,
  nthQuantity,
  type,
  value,
  cartQuantity,
  discountPerBulk,
  itemOriginalPrice,
}: Props) => {
  const remaining = nthQuantity - (cartQuantity % nthQuantity);
  const progression =
    cartQuantity % nthQuantity === 0 ? 100 : ((nthQuantity - remaining) / nthQuantity) * 100;

  const benefitTimes = Math.floor(cartQuantity / nthQuantity);

  return (
    <S.Container style={{ opacity: isVisible ? 1 : 0 }}>
      <S.RemainingQuantityLabel>
        {cartQuantity % nthQuantity === 0 && cartQuantity > 0 ? (
          I18n.t('price.batch-discount-label.discount-applied')
        ) : (
          <>
            <span>
              {I18n.t('price.batch-discount-label.one-out-of', {
                quantity: nthQuantity - remaining,
                goal: nthQuantity,
              })}
            </span>
            {`${I18n.t('price.batch-discount-label.for-reward')} ${formatBatchDiscountNthText({
              discountPerBulk,
              itemOriginalPrice,
              nthQuantityOffered: nthQuantity * (benefitTimes + 1),
              type,
              value,
            })}`}
          </>
        )}
      </S.RemainingQuantityLabel>
      <S.RewardContainer>
        <S.RewardProgression progression={cartQuantity === 0 ? 0 : progression} />
      </S.RewardContainer>
    </S.Container>
  );
};

export default NthPromoTooltip;
