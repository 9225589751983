import { useQueryClient } from '@tanstack/react-query';

import { UpdateCartProduct } from '~/src/common/hooks/cart/types';
import { useStable } from '~/src/common/hooks/useStable';
import { useApiEcomClientError } from '~/src/common/services/error-handler/error-handler';
import { Cart, CartProduct } from '~/src/common/typings/cart';
import { compact } from '~/src/common/utils/array';
import { sendQuantityUpdateEvents } from '~/src/common/utils/cart/cart';
import { getCanonicalIdFromId, isSameProduct } from '~/src/common/utils/product';
import {
  getGetCartQueryKey,
  getRecompute2QueryKey,
  useAddProduct,
} from '~/src/queries/api-ecom/generated/api-ecom';
import {
  AddProductBody,
  AddProductBodyProductAddToCartSource,
  Recompute2200,
} from '~/src/queries/api-ecom/generated/api-ecom.schemas';
import { ApiEcomError } from '~/src/queries/services/client';
import { MUTATION_KEYS } from '~/src/screens/App/queries/queries';

export interface UpdateCartProductQuantityPayload {
  product: UpdateCartProduct;
  productEventSource: AddProductBodyProductAddToCartSource;
  categoryName?: string;
  subcategoryName?: string;
  onRefresh?: () => void;
  onError?: (error: ApiEcomError) => void;
  onSuccess?: ({
    quantity,
    currentQuantity,
  }: {
    quantity: number;
    currentQuantity: number;
  }) => void;
  shouldSendAnalyticsEvents?: boolean;
}

export const useUpdateCartProductQuantity = ({
  product,
  productEventSource,
  categoryName: CategoryNameFromProduct,
  subcategoryName,
  onRefresh,
  onError,
  onSuccess,
  shouldSendAnalyticsEvents = true,
}: UpdateCartProductQuantityPayload) => {
  const queryClient = useQueryClient();
  const handleError = useApiEcomClientError();

  const { mutate, ...useMutationRestProps } = useAddProduct({
    mutation: {
      mutationKey: MUTATION_KEYS.updateCartQuantity,
      onMutate: async ({
        data: {
          product: { id, quantity },
        },
      }: {
        data: AddProductBody;
      }) => {
        await queryClient.cancelQueries(getGetCartQueryKey());
        const previousCart = queryClient.getQueryData<Cart>(getGetCartQueryKey());
        const previousCartProduct = previousCart?.products?.find(p => isSameProduct(p, product));

        if (shouldSendAnalyticsEvents) {
          sendQuantityUpdateEvents({
            queryClient,
            quantity,
            productEventSource,
            product,
            CategoryNameFromProduct,
            subcategoryName,
          });
        }

        const expectedProduct = previousCartProduct
          ? {
              ...previousCartProduct,
              quotation2: {
                ...previousCartProduct.quotation2,
                count: {
                  ...previousCartProduct.quotation2.count,
                  quantity,
                },
              },
            }
          : ({
              id,
              quotation2: {
                count: {
                  quantity,
                },
              },
            } as CartProduct);

        const updatedCartProducts = previousCartProduct
          ? (previousCart?.products || []).map(p => (p.sku === product.sku ? expectedProduct : p))
          : [...(previousCart?.products || []), expectedProduct];

        // Mise à jour optimiste du panier
        if (previousCart) {
          queryClient.setQueryData<Cart>(getGetCartQueryKey(), {
            ...previousCart,
            products: compact(updatedCartProducts),
          });

          // Mise à jour optimise du panier issu du recompute
          const currentRecomputedData = queryClient.getQueryData<Recompute2200>(
            getRecompute2QueryKey(),
          );

          if (currentRecomputedData) {
            queryClient.setQueryData<Recompute2200>(getRecompute2QueryKey(), {
              ...currentRecomputedData,
              products: compact(updatedCartProducts),
            });
          }
        }

        // Return a context object with the snapshotted value
        return { previousCart };
      },
    },
  });

  const customMutate: typeof mutate = useStable((args, options) => {
    return mutate(
      {
        ...args,
        data: {
          ...args.data,
          product: {
            ...args.data.product,
            id: getCanonicalIdFromId(args.data.product.id),
            addToCartSource: productEventSource,
          },
        },
      },
      {
        onSuccess: (
          cart,
          {
            data: {
              product: { quantity },
            },
          },
          { previousCart },
        ) => {
          const previousCartProduct = previousCart?.products?.find(p => p.sku === product.sku);
          const previousQuantity = previousCartProduct?.quotation2.count.quantity ?? 0;

          queryClient.setQueryData<Cart>(getGetCartQueryKey(), cart as Cart);

          queryClient.setQueryData<Recompute2200>(getRecompute2QueryKey(), cart);

          onSuccess?.({ quantity, currentQuantity: previousQuantity });
        },
        onError: (error: unknown, _, context) => {
          const typedError = error as ApiEcomError;
          queryClient.setQueryData(getGetCartQueryKey(), context?.previousCart);

          if (onError) onError(typedError);
          else handleError(typedError);

          onRefresh?.();
        },
        ...options,
      },
    );
  });

  return { mutate: customMutate, ...useMutationRestProps };
};
