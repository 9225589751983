import { useState } from 'react';

import dynamic from 'next/dynamic';

import { useScreenSize } from '~/src/common/hooks/useScreenSize';

import FamilyCard from '../FamilyCard/FamilyCard';
import MiniMenu from '../MiniMenu/MiniMenu';
import { useNavigationTracking } from '../tracking';
import type * as T from '../types';

import * as S from './layout';

// Chargé dynamiquement pour alléger la taille de la réponse initiale
const MegaMenu = dynamic(async () => import('../MegaMenu/MegaMenu'), { ssr: false });

type Props = {
  active: boolean;
  family: T.Family;
  onClick: () => void;
};

const FamilyItem = ({ family, active, onClick }: Props) => {
  const { isLargeScreen } = useScreenSize();
  const [isMounted, setMounted] = useState(false);
  const { onClickFamilyCard } = useNavigationTracking().large;

  // Combinaison du click et du tracking
  const handleClick = () => {
    onClickFamilyCard(family.id, active ? 'close' : 'open');
    onClick();
  };

  return (
    <S.Container aria-selected={active}>
      <FamilyCard family={family} onClick={handleClick} />
      {isMounted ? null : <MiniMenu family={family} />}
      {isLargeScreen && <MegaMenu family={family} onMount={setMounted} />}
    </S.Container>
  );
};

export default FamilyItem;
