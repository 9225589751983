import { useRouter } from 'next/router';

import { useAuthenticated } from '~/src/common/hooks/user/useAuthenticated';
import { useScreenSize } from '~/src/common/hooks/useScreenSize';
import { removeAllModals, useNiceModal } from '~/src/common/services/ModalsManager';
import Tracker from '~/src/common/services/Tracker';
import { getArticlesCount } from '~/src/common/utils/cart/cart';
import { isRemotePickup } from '~/src/common/utils/delivery';
import { isOrderBeingDelivered, isOrderBeingPrepared } from '~/src/common/utils/orders';
import { useCurrentOrders, useGetCart } from '~/src/queries/api-ecom/generated/api-ecom';
import { BannerMessage } from '~/src/screens/AccountManagementModal';

const useNavigationActions = () => {
  const router = useRouter();
  const isAuthenticated = useAuthenticated();
  const { isLargeScreen } = useScreenSize();
  const { data: cart } = useGetCart();

  const { show: openUserPersonalListsModal } = useNiceModal('user-personal-lists-modal');
  const { show: openCartModal } = useNiceModal('cart-modal');
  const accountManagementModal = useNiceModal('account-management-modal');

  const { data: ordersData } = useCurrentOrders({
    query: {
      enabled: isAuthenticated && !router.pathname.startsWith('/account/'),
      staleTime: Infinity,
    },
  });

  const hasOngoingOrders =
    (ordersData?.items?.filter(
      order =>
        isOrderBeingDelivered(order.state) ||
        isOrderBeingPrepared(order.state, isRemotePickup(order.delivery.deliveryZone?.type)),
    ).length || 0) > 0;
  const cartProductsQuantity = getArticlesCount(cart);

  const eventName = 'user navigation menu';

  const handleLogoClick = () => {
    Tracker.sendEvent(eventName, { 'navigation text': 'logo' });

    if (router.pathname === '/') {
      removeAllModals();
    }
  };

  const handleRecipesClick = () => {
    Tracker.sendEvent(eventName, { 'navigation text': 'recettes' });

    void router.push('/categorie/nos-recettes');
  };

  const handleListsClick = () => {
    if (isAuthenticated) {
      void openUserPersonalListsModal();
    } else {
      void accountManagementModal.show({
        banner: { icon: 'heart-normal', message: BannerMessage.DisplayBookmarksList },
      });
    }

    Tracker.sendEvent(eventName, { 'navigation text': 'lists' });
  };

  const handleAccountClick = () => {
    Tracker.sendEvent(eventName, { 'navigation text': 'account' });

    if (isAuthenticated) {
      const destination = isLargeScreen ? '/account/orders' : '/account';
      void router.push(destination);
    } else {
      void accountManagementModal.show();
    }
  };

  const handleCartClick = () => {
    void openCartModal();

    Tracker.sendEvent('user navigation basket');
  };

  const handleMyProducts = () => {
    Tracker.sendEvent(eventName, { 'navigation text': 'mes produits' });

    const navigateToMyProducts = () => void router.push('/mes-produits');
    if (isAuthenticated) {
      navigateToMyProducts();
    } else {
      void accountManagementModal.show({
        onSigninSuccess: navigateToMyProducts,
        onSignupSuccess: navigateToMyProducts,
      });
    }
  };

  return {
    hasOngoingOrders,
    cartProductsQuantity,
    handleRecipesClick,
    handleLogoClick,
    handleListsClick,
    handleAccountClick,
    handleCartClick,
    handleMyProducts,
  };
};

export default useNavigationActions;
