import { BusinessErrors } from '@keplr/api-catalog-errors';

import { HandlerPayload } from '~/src/common/services/error-handler/business-error/business-errors-handlers';
import { isModalVisible, showNiceModal } from '~/src/common/services/ModalsManager';
import { Cart } from '~/src/common/typings/cart';
import { verifyAndUpdateDelivery } from '~/src/common/utils/cart/verifyAndUpdateDelivery';
import { noop } from '~/src/common/utils/function';
import { getGetCartQueryKey } from '~/src/queries/api-ecom/generated/api-ecom';
import { isDelivery } from '~/src/screens/App/CatalogLayout/utils';

const { Order } = BusinessErrors;
/**
 * Erreur E_08_0001 - Créneau horaire XXXXX inconnu
 * Apparaît quand le créneau a été invalidé côté BO
 * Comportement :
 * Si le créneau a expiré le jour même
 * Alors on affiche la modal d'expiration de créneau qui contient un lien vers la modal de livraison
 * Sinon on affiche directement la modal de livraison
 */

export default {
  code: Order.InvalidTimeSlot.code,
  handler: ({ store }: HandlerPayload) => {
    const isDeliveryModalOpen = isModalVisible(store?.modalsState, 'delivery-modal');
    const isExpiredDeliverySlotModalOpen = isModalVisible(
      store?.modalsState,
      'expired-delivery-slot-modal',
    );
    const { queryClient } = store || {};
    const currentCart = queryClient?.getQueryData<Cart>(getGetCartQueryKey());

    const currentDzId = currentCart?.delivery?.deliveryZone.id;

    // On vérifie qu'aucune des deux modales n'est ouverte
    if (!isExpiredDeliverySlotModalOpen && !isDeliveryModalOpen) {
      if (currentCart != null && currentDzId) {
        // on affiche la modale créneau expiré si l'on est sur le checkout
        if (/\/checkout/.test(window.location.pathname)) {
          showNiceModal('expired-delivery-slot-modal', {
            isDelivery: isDelivery(currentCart.delivery),
          }).catch(noop);
        }
        // Sinon on sélectionne automatiquement un créneau et on affiche un toaster
        else {
          if (queryClient) {
            void verifyAndUpdateDelivery(queryClient, currentCart);
          }
        }
      }
    }
  },
};
